import React from 'react';

const Banner = () => {
    return (
        <div className='bg-gradient-to-t from-[#020202] to-[#4f4f4f] w-full h-fit p-8
        lg:p-16'> 
            <p className='text-base text-white font-semibold text-center 
                        md:text-lg 
                        lg:text-xl
                        xl:text-2xl 
                        2xl:text-3xl'>
                Keep your loved ones safe with our cutting-edge home security technology
            </p>
        </div>
    )
}

export default Banner;