import React, { useEffect, useState } from "react";
import Progress from "./Progress";
import config from "../config/index.json";
import {isMobile} from 'react-device-detect';
import SelectButtonGroup from "./SelectButtonGroup";
import InputFieldGroup from "./InputFieldGroup";
import ThankYou from "./ThankYou";
import dateFormat from "dateformat";

const Chat = ({count, setCount, expand, expandCallback}) => {
  	const { chat } = config;
	const [translate, setTranslate] = useState(false)
	const [formData, setFormData] = useState({
		optinurl: window.location.href,
		transaction_id: crypto.randomUUID(),
		optindate: dateFormat(new Date().toISOString(), "yyyy-mm-dd hh:MM:ss"),
	})
	const [error, setError] = useState(0)
	const [emailValid, setEmailValid] = useState(2)
	const [phoneValid, setPhoneValid] = useState(2)
	const [zipcodeValid, setZipcodeValid] = useState(2)
	const [formValid, setFormValid] = useState(0)
	const [checkboxValid, setCheckboxValid] = useState(2)

	function handleOptionSelect(option) {
		if (count === 0) {
			setTranslate(true)
			expandCallback(true)
		}    

		setFormData({
			...formData,
			[chat.questions[count].property]: option.value
		})
		setError(0)
		setCount(count + 1)
	}

	async function handleSubmitButton() {
		if (chat.questions[count].options[0].property === 'firstname' && "firstname" in formData && "lastname" in formData && "email" in formData && 'postcode' in formData && "phone1" in formData && emailValid === 0 && phoneValid === 0 && checkboxValid === 0) {
			setTranslate(true)
			expandCallback(true)
			setFormValid(1)
			setCount(count + 1)

			const params = new Proxy(new URLSearchParams(window.location.search), {
				get: (searchParams, prop) => searchParams.get(prop),
			});

			try {
				let res = await fetch(`https://vmpleads.leadbyte.co.uk/api/submit.php?returnjson=yes&campid=IE---HOME-SECURITY&sid=${params.aff_id}&email=${encodeURIComponent(formData.email)}&firstname=${formData.firstname}&lastname=${formData.lastname}&postcode=${formData.postcode}&phone1=${formData.phone1}&source=${params.source}&optinurl=${encodeURIComponent(formData.optinurl)}&optindate=${encodeURIComponent(formData.optindate)}&aff_sub=${params.aff_sub}&aff_sub2=${params.aff_sub2}&transaction_id=${formData.transaction_id}`, {
					method: 'GET',
					mode: 'no-cors'
				})
				console.log(res)
				window.fbq('track', 'LEAD');
			} catch (error) {
				console.log(error)
			}
		}
		else {
			setError(1)
		}
	}

	function handleEmail(e, option) {
		option.value = e.target.value
		setFormData({
			...formData,
			email: e.target.value
		})
		if (String(e.target.value).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
			setEmailValid(0)
		}
		else {
			setEmailValid(1)
		}
		console.log(emailValid)
	}

	function handlePhone(e, option) {
		option.value = e.target.value
		setFormData({
			...formData,
			phone1: e.target.value
		})
		if(String(e.target.value).match(/^(\+353|353|0)(1|2|4|5|6|9)\d{7}$/)) {
			setPhoneValid(0)
		}
		else {
			setPhoneValid(1)
		}
	}

	function handleZipcode(e, option) {
		option.value = e.target.value
		setFormData({
			...formData,
			postcode: e.target.value
		})
		if(String(e.target.value).match(/^[a-zA-Z0-9]{7}$/)) {
			setZipcodeValid(0)
		}
		else {
			setZipcodeValid(1)
		}
	}

	function handleNames(e, option) {
		option.value = e.target.value
		if (e.target.value.trim() !== '') {
			setFormData({
				...formData,
				[option.property]: e.target.value 
			})
		}
		else {
			setError(!error)
		}
	}

  	function renderSwitch(question) {
		switch(question.type) {
			case 'Button':
				return  (
					<SelectButtonGroup question={question} handleSelect={handleOptionSelect} count={count} expand={expand} error={error}/>
				)
			case 'Form':
				return <InputFieldGroup 
					question={question} 
					handleEmail={handleEmail} 
					handleNames={handleNames} 
					handlePhone={handlePhone} 
					handleZipcode={handleZipcode} 
					error={error} 
					emailValid={emailValid} 
					phoneValid={phoneValid} 
					zipcodeValid={zipcodeValid}
					setCheckboxValid={setCheckboxValid}
					checkboxValid={checkboxValid} /> 
				
			case 'ThankYou':
				return (
					<ThankYou />
				)
			default:
				return null
		}
  	}

  	return (
		<div className={`z-50 mx-auto w-full h-fit relative transition duration-700 ease-linear mx-auto font-helvetica ` + (expand === true ? " sm:w-2/3" : "")}>

			{/* Progress Bar */}
			{expand 
				? <div className={`w-full py-6 max-[645px]:px-[34px]
				flex items-center justify-center rounded-t-[20px] text-center text-white bg-[#f0f0f0] `}>
					<div className="font-bold flex w-full md:w-4/5 xl:text-xl lg:text-lg md:text-base text:sm text-center justify-center"></div>	
				</div>
				: <div className={`w-full py-6 max-[645px]:px-[34px]
				flex items-center justify-center rounded-t-[20px] text-center text-white bg-gradient-to-t from-[#020202] to-[#4f4f4f] `}>
					<div className="font-bold flex w-full md:w-4/5 xl:text-xl lg:text-lg md:text-base text:sm text-center justify-center">Complete this form and get your free quote</div>		
				</div>
			}

			<div className={"h-full pt-6 pb-10 w-full flex flex-col items-center justify-between rounded-b-[20px] shadow-xl bg-[#F0F0F0]" + (expand ? " px-10" : " px-5")}>
				
				<div className="text-center mb-4 max-[645px]:h-fit">
					<p className="w-fit m-auto font-semibold xl:text-xl lg:text-lg md:text-base text-sm">
						{chat.questions[count]?.question}
					</p>
					{
						chat.questions[count]?.info ? <p className="w-fit m-auto text-[#2F2828] xl:text-xl lg:text-lg md:text-base text-sm">{chat.questions[count]?.info}</p> : null
					}
				</div>

				{/* Render the answer options */}
				{renderSwitch(chat.questions[count])}


				
				{ count !== chat.questions.length - 1 ? 
				<div className={expand ? " lg:w-1/2 w-full " : "w-full"}>
					<button className='w-full bg-gradient-to-t from-[#bc0000] to-[#e10707] text-white xl:text-2xl md:text-lg text-sm py-4 px-16
					rounded-full font-bold hover:bg-gradient-to-t hover:from-[#bc0000] hover:to-[#bc0000]' 
					onClick={handleSubmitButton}>
						{chat.questions[count]?.submit_message}
					</button>
				</div> : null}
			</div>
		</div>
  	)
}

export default Chat
