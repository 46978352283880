import { React, useState, useEffect } from "react";

import Chat from "./Chat";
import Footer from './Footer';

const Section1 = ({expand, expandChat}) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1280);
    const [count, setCount] = useState(0)

    const handleResize = () => {
        setIsMobile(window.innerWidth < 1280);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className={(expand === true ? "w-full h-screen overflow-hidden" : "relative 2xl:px-64 xl:px-40 relative w-full h-fit")}>
            {expand && (
                <>
                    <img src="./assets/images/RedBg2.svg" className="absolute -top-96 left-0 overflow-hidden w-full -z-10" />
                    <div className="absolute bottom-0 w-full">
                        <Footer />
                    </div>
                </>
            )}
            {!expand && (
                <>
                    <img
                        src="./assets/images/RedBg.svg"
                        className='-z-10 absolute xl:-left-28 xl:-top-10 2xl:left-0 2xl:top-0 2xl:w-3/4 xl:w-4/5 w-full'
                    />
                    <div className="flex justify-center md:py-12 py-8">
                        <img src="./assets/images/LogoHomeSecurity.png" className="2xl:w-1/5 md:w-1/5 w-1/3"/>
                    </div>
                </>
            )}
            <div className="flex justify-between items-center xl:flex-row flex flex-col lg:mb-0 2xl:mb-32 2xl:px-5">
                {!expand && (
                    <div className="md:flex-col 2xl:w-[40%] xl:w-3/5 md:w-4/5 justify-evenly mx-auto">
                        <div className="xl:text-3xl xl:text-left lg:text-2xl text-center md:text-xl text-lg text-white font-bold mb-4 md:w-fit w-4/5 mx-auto">
                            Shield your home from all sides with our advanced security systems
                        </div>

                        {isMobile ? (
                                <div className="2xl:w-1/2 xl:w-4/5 lg:w-3/5 mx-auto md:w-4/5 sm:w-3/4 w-11/12">
                                    <Chat count={count} setCount={setCount} expand={expand} expandCallback={expandChat} />
                                </div>
                        ) : null
                        }
                        
                        <div className="flex flex-row xl:flex-col justify-center items-center xl:w-full md:w-4/5 sm:w-2/3 w-11/12 mx-auto">
                            <div className="flex flex-col xl:text-white xl:text-xl lg:text-lg md:text-base text-sm">
                                <div className="flex items-center w-fit">
                                    <img src="./assets/images/Icon24-7W.png" alt="" className="w-1/5 hidden xl:block" />
                                    <img src="./assets/images/Icon24-7B.png" alt="" className="w-1/6 block xl:hidden" />
                                    <p className="ml-4 w-fit">24/7 Monitoring</p>
                                </div>
                                <div className="flex items-center w-fit">
                                    <img src="./assets/images/IconEyesW.png" alt="" className="w-1/5 hidden xl:block" />
                                    <img src="./assets/images/IconEyesB.png" alt="" className="w-1/6 block xl:hidden" />
                                    <p className="ml-4 w-fit">Intrusion detection</p>
                                </div>
                                <div className="flex items-center w-fit">
                                    <img src="./assets/images/IconPhoneW.png" alt="" className="w-1/5 hidden xl:block" />
                                    <img src="./assets/images/IconPhoneB.png" alt="" className="w-1/6 block xl:hidden" />
                                    <p className="ml-4 w-fit">Mobile App</p>
                                </div>
                            </div>
                            <div className="2xl:mt-0 xl:mt-8 xl:w-full lg:w-[200%] md:w-[400%] sm:w-[500%] w-[500%]">
                                <img src="./assets/images/Stuffs.png" alt="" className="rounded-2xl"/>
                            </div>
                        </div>
                    </div>
                )}
                
                {!isMobile ? (
                    expand ? (
                        <div className="2xl:w-1/2 xl:w-4/5 lg:w-3/5 lg:mx-auto md:w-3/5 w-11/12 h-full flex items-center justify-center 2xl:mt-40 xl:mt-20 lg:10">
                            <Chat count={count} setCount={setCount} expand={expand} expandCallback={expandChat} />
                        </div>
                    )
                    : (
                        <div className="2xl:w-1/2 xl:w-4/5 lg:w-3/5 lg:mx-auto md:w-3/5 w-11/12">
                            <Chat count={count} setCount={setCount} expand={expand} expandCallback={expandChat} />
                        </div>
                    )
                ) : null}

                {/*Part to fix*/}
                {isMobile ? (
                    expand ? (
                        <div className="2xl:w-1/2 xl:w-4/5 lg:w-4/5 mx-auto md:w-4/5 sm:w-full w-11/12 mt-20">
                            <Chat count={1} setCount={setCount} expand={expand} expandCallback={expandChat} />
                        </div>
                    ) : null
                ) : null}
            </div>
        </div>
    );
}

export default Section1;