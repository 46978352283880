import React from "react";

const Footer = ({expand}) => {
    return (
        <div className={"z-50 bg-gradient-to-t from-[#020202] to-[#4f4f4f] sm:mt-0"}>
            <div className='xl:text-lg
                            lg:text-base
                            md:text-sm 
                            sm:text-xs text-[0.65rem] text-white text-center py-[28px] font-semibold max-[645px]:py-[12px] max-[645px]:w-[360px] max-[645px]:mx-auto'>
                Copyright © 2023 Vertigo Media SL also operating under the name « Home Security ». Your informations are protected.<br />
                <a className="hover:underline max-[640px]:underline" href="https://www.vertigomediaperformance.com/privacyandpolicy-ie/" target="_blank" rel="noreferrer">Privacy Policy</a> and <a className="hover:underline max-[640px]:underline" href="https://www.vertigomediaperformance.com/terms-conditions-ie/" target="_blank" rel="noreferrer">Legal Notice</a>
            </div>
        </div>
    )
}

export default Footer;