import React from "react"

function SelectButtonGroup({question, handleSelect, count, expand, error}) {
    return (
        <>
            <div className={"w-full sm:w-2/5 flex items-stretch flex-wrap justify-between " + 
            (count === 0 ? " sm:!w-full " : " ") +
            (expand && count === 2 ? " sm:!w-full " : " ") + 
            (expand && count === 4 ? " sm:!w-full " : " ") }>
                {question.options.map((option, i) => {
                    let style = "shadow rounded-3xl bg-white mb-3 "
                    
                    if (count == 0)
                        style += option.img !== undefined && option.img ? "w-[32%] py-2 px-1 sm:py-5 flex flex-col justify-end sm:hover:bg-[#0056ff] sm:hover:text-black sm:hover:invert" : " px-16 py-5 rounded-full sm:hover:bg-[#ffa900] sm:hover:text-white"
                    else if (count == 2)
                        style += option.img !== undefined && option.img ? "sm:px-3 py-4 w-[49%] mb-2 sm:w-[24%] sm:py-10 sm:hover:bg-[#ffa900] max-sm:h-[90px] sm:hover:text-white" : " max-sm:h-[90px] w-[49%] px-3 py-[22px] sm:py-[36px] sm:hover:bg-[#ffa900] sm:hover:text-white"
                    else 
                        style += option.img !== undefined && option.img ? "w-[49%] py-[10px]  sm:py-[40px] px-2 sm:hover:bg-[#ffa900] sm:hover:text-white" : "px-2 py-7 sm:px-10 sm:hover:bg-[#ffa900] sm:hover:text-white  sm:py-14 w-[49%] sm:w-[24%]"

                    return <button
                        key={i}
                        className={style} 
                        onClick={() => handleSelect(option)} >
                        
                        {option.img !== undefined && option.img ?

                            <div className={ "flex justify-center " + 
                                            (count == 2 ? " sm:mx-auto" : " h-[50px]") + 
                                            (count == 0 ? " h-full w-full " : (count == 2 ? " " : "  ")) }>
                                <img src={option.img} alt={option.value} className={(count == 2 ? "w-1/2" : "w-full")}/>
                            </div> : null
                        }
                        {option.value !== undefined || option.value !== "" ?
                            <p className="text-center w-full lg:text-lg md:text-base sm:text-sm text-xs p-0">{option.value}</p> : null
                        }
                    </button>
                }
                )}
            </div>
            {error === 1 ? <p className="text-red-500 font-semibold text-xl mb-4">Champ Obligatoire</p> : ""}
        </>
    )
}

export default SelectButtonGroup