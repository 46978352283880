import React, { useState } from 'react'

import Section1 from './components/Section1';
import Section2 from './components/Section2';
import Section3 from './components/Section3';
import Footer from './components/Footer';
import Banner from './components/Banner';

const App = () => {
	const [expand, setExpand] = useState(false)

	const expandChat = (state) => { 
		setExpand(state) 
	}

	return (
		<div className="overflow-hidden font-sans">
			<Section1 expand={expand} expandChat={expandChat} />
			{!expand && (
				<div className=''>
					<Section2 />
					<Banner />
					<Section3 />
					<Footer />
				</div>
			)}
		</div>
	);
};

export default App;