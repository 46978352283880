import React from "react";

import Button from "./Button";

const Section2 = () => {
    return (
        <div className="2xl:px-64 xl:px-40 xl:py-20 lg:pt-72 md:pt-40 md:px-10 sm:pt-10 pt-10 w-full h-fit z-0 relative overflow-hidden">
            <div className="flex md:flex-row md:gap-8 2xl:justify-center justify-between flex-col sm:mb-16 items-center">
                <div className="flex flex-col md:w-1/2 sm:w-3/4 w-11/12 mb-8 lg:mb-0">
                    <div className="xl:text-3xl 
                    lg:text-2xl 
                    md:text-xl 
                    text-lg text-left font-bold mb-4 h-fit text-black">
                        Why install a home security system?
                    </div>
                    <div className="xl:text-xl
                    lg:text-lg
                    md:text-base
                    text-sm text-left mb-8 h-fit text-black">
                        Our home security system provides a sense of security and peace of mind knowing that your property and loved ones are protected. 
                        It acts as a deterrent to potential burglars and intruders, minimizing the risk of a break-in. 
                        Our home security system can also remotely monitor your home, allowing you to check on your property and loved ones from anywhere, at any time. 
                        It is a valuable investment that can provide long-term security and protection for your home and family.
                    </div>
                    <Button />
                </div>
                <div className="2xl:w-2/5 lg:w-1/2 md:w-1/2 sm:w-3/4 w-full">
                    <img src="./assets/images/ManCameraImg.png" className="hidden sm:block" />
                    <img src="./assets/images/ManCameraImgFlat.png" className="block sm:hidden" />
                </div>
            </div>
        </div>
    );
}

export default Section2;