import React from "react";

const Button = () => {
    return (
        <button className="2xl:py-6 2xl:px-32
        xl:text-2xl xl:px-20
        lg:text-xl lg:py-5 lg:px-16
        md:text-lg md:py-4 md:px-12
        sm:py-3
        py-4 px-16 text-base text-white text-center font-bold bg-gradient-to-t from-[#bc0000] to-[#e10707] h-fit w-fit rounded-full
        hover:bg-gradient-to-t hover:from-[#bc0000] hover:to-[#bc0000]" 
            onClick={() => window.scrollTo({top: 0, behavior: "smooth"})}>
            Learn more
        </button>
    )
}

export default Button;