import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";

function Progress({expand, count}) {

    let progress_1 = Math.floor(((count + 1) * 100) / 6)
    let progress_2 = count > 5 ? Math.floor(((count - 5) * 100) / 2) : 0


    return (
        <div className="w-full relative sm:h-[40px] h-[20px] flex items-center justify-center grow shrink">
            <div className="h-full sm:w-[40px] w-[20px] bg-[#182050] rounded-full"></div>
            <ProgressBar
                    completed = { progress_1 }
                    isLabelVisible = { false }
                    bgColor = "#182050"
                    baseBgColor = "#ffffff"
                    className="w-[300px] max-[645px]:w-[75px]"
                    height = {expand ? "4px" : "8px"}
                    borderRadius="0px"
            />
            <div className={"h-full sm:w-[40px] w-[20px] rounded-full  bg-gradient-to-r from-[#182050] to-50% from-50% to-[#ffffff] bg-[length:200%_100%]  transition-all duration-1000 ease-out " + (progress_1 >= 100 ? " bg-left-bottom delay-1000" : "bg-right-bottom")}></div>
            <ProgressBar
                    completed = { progress_2 } 
                    isLabelVisible = { false }
                    bgColor = "#182050"
                    baseBgColor = "#ffffff"
                    className="w-[300px] max-[645px]:w-[75px]"
                    height = {expand ? "4px" : "8px"}
                    borderRadius="0px"
            />
            <div className={"h-full sm:w-[40px] w-[20px] rounded-full bg-gradient-to-r from-[#182050] to-50% from-50% to-[#ffffff] bg-[length:200%_100%] transition-all duration-500 ease-out delay-1000 " + (progress_2 >= 100 ? " bg-left-bottom delay-1000" : "bg-right-bottom") }></div>

        </div>        
    )
}

export default Progress