import React from "react";

function ThankYou() {
    return (
        <>
            <div className="mb-[30px]"><img src="./assets/images/check.png" width="110px"/></div>
            <div className="text-xl max-[645px]:text-[12px] text-[20px] text-center mb-[67px]" ><b>We have considered your request.</b> <br/>We have sent your information to the most appropriate professionals according to your needs.<br/>They will contact you as soon as possible.</div>
            <div className="text-sm  max-[645px]:text-[10px] text-center mb-[10px] weight-light" >You can unsubscribe at any time by sending an email to <br/>dpo@vertigomediaperformance.com</div>
        </>
    )
}

export default ThankYou