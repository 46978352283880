import React from "react";

function InputFieldGroup({question, handleEmail, handleNames, handlePhone, handleZipcode, error, emailValid, phoneValid, zipcodeValid, setCheckboxValid, checkboxValid}) {
    
    function createLinksText(value, links) {
        links.forEach(({ text, link }) => {
            let escapedText = text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
            const regex = new RegExp(escapedText, "g");
            value = value.replace(regex, `<a href="${link}" target="_blank" rel="noopener noreferrer" class="underline">${text}</a>`);
        });
        return value;
    }

    function handleCheckboxClick(option) {
        if (option.required === true) {
            if (checkboxValid === 0) {
                setCheckboxValid(1)
            } else {
                setCheckboxValid(0)
            }
        }
    }

    return (
        <div className="w-full sm:w-3/4 flex flex-col justify-center items-center">
                {question.options.map((option, i) =>
                    <div key={i} className="w-full">
                        { option.type === "TextField" ?
                            <div className={"w-full pb-2 mb-4 "}>
                                <input
                                    value={option.value}
                                    type="text"
                                    autoComplete="false"
                                    onChange={
                                        option.property === "email" ? (e) => handleEmail(e, option) :
                                        (option.property === "phone1" ? (e) => handlePhone(e, option) :
                                        (option.property === "postcode" ? (e) => handleZipcode(e, option) : 
                                        (option.type === "TextField" && (option.property !== "email" && option.property !== "phone" && option.property !== "zipcode") ? (e) => handleNames(e, option) : null)))
                                    } 
                                    placeholder={ option.placeholder }  
                                    className={"w-full rounded-[30px] py-3 pl-[33px] shadow-md placeholder:text-[#505050] xl:placeholder:text-xl lg:placeholder:text-lg md:placeholder:text-base placeholder:text-sm"} />
                                    {
                                        (option.property === "email") ? emailValid === 1 && <p className="text-red-500 text-xs sm:text-sm md:text-base lg:text-lg">Please fill in a valid email address.</p> :
                                        (option.property === "phone1") ? phoneValid === 1 && <p className="text-red-500 text-xs sm:text-sm md:text-base lg:text-lg">Please fill in a valid phone number.</p> :
                                        (option.property === "postcode") ?  zipcodeValid === 1 && <p className="text-red-500 text-xs sm:text-sm md:text-base lg:text-lg">Please fill in a valid zipcode.</p> : ""
                                    }
                            </div> : null
                        }

                        {option.type === "Text" ? 
                            <p className="text-left xl:text-lg lg:text-base md:text-sm text-xs pb-2 mx-auto max-[645px]:w-fit mb-4" 
                            dangerouslySetInnerHTML={{ __html: createLinksText(option.value, option.links)}}>
                            </p>: null
                        }

                        { option.type === "Ckeckbox" ? (
                            <>
                                <div className={"w-full flex flex-row gap-4 text-left xl:text-base lg:text-sm md:text-xs text-xs " + ((checkboxValid === 1 && option.required === true) ? "mb-2" : "mb-8")}>
                                    <input type="checkbox" onClick={() => handleCheckboxClick(option)}/>
                                    <label dangerouslySetInnerHTML={{ __html: createLinksText(option.value, option.links)}}></label>
                                </div>
                                {checkboxValid === 1 && option.required === true && <p className="text-red-500 text-xs sm:text-sm md:text-base lg:text-lg">Please check the box.</p>}
                            </>
                            ) : null
                        }
                    </div>
                )}
                {error === 1 ? <p className="text-red-500 font-semibold text-xl mb-4">Fields required</p> : ""}
        </div>
    )
}

export default InputFieldGroup