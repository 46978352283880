import React from "react";

import Button from "./Button";

const Section3 = () => {
    return (
        <div className="w-full h-fit z-0 relative sm:p-10 sm:py-20 p-5 py-16">
            <div className="xl:text-3xl 
            lg:text-2xl 
            md:text-xl md:w-4/5 md:pb-20
            text-lg pb-8 w-11/12 mx-auto font-bold text-center h-fit">
                Our services
            </div>
            <div className="lg:p-10 md:flex-row lg:mb-16
            w-4/5 flex flex-col mx-auto xl:text-xl lg:text-lg md:text-base text-sm">
                <div className="mb-8 md:w-3/5 sm:w-4/5 flex flex-row md:mb-0 md:flex-col items-center justify-around">
                    <img src="./assets/images/24-7Form.png" alt="" className="xl:w-1/2 md:w-2/3 sm:w-1/3 w-2/5 mb-4"/>
                    <p className="w-[134px] md:w-fit">24/7 Monitoring</p>
                </div>
                <div className="mb-8 md:w-3/5 sm:w-4/5 flex flex-row md:mb-0 md:flex-col items-center justify-around">
                    <img src="./assets/images/EyesForm.png" alt="" className="xl:w-1/2 md:w-2/3 sm:w-1/3 w-2/5 mb-4"/>
                    <p className="w-[134px] md:w-fit">Intrusion detection</p>
                </div>
                <div className="mb-8 md:w-3/5 sm:w-4/5 flex flex-row md:mb-0 md:flex-col items-center justify-around">
                    <img src="./assets/images/PhoneForm.png" alt="" className="xl:w-1/2 md:w-2/3 sm:w-1/3 w-2/5 mb-4"/>
                    <p className="w-[134px] md:w-fit">Mobile app</p>
                </div>
            </div>
            <div className="w-fit mx-auto md:mt-20 mt-8">
                <Button />
            </div>
        </div>
    )
}

export default Section3;